import styled from "styled-components";

import { Box, Checkbox } from "@mui/material";

//
//
// ---------------------------------------------------------------
// -------------------------- App --------------------------------
// ---------------------------------------------------------------
export const WrapperSC = styled(Box)`
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.palette.neutral50};
  overflow-x: hidden;
  position: relative;

  @media (max-width: 1024px) {
    position: relative;
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const InnerWrapperSC = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 479px;
  width: 100%;

  @media (max-width: 1024px) {
    min-height: 0;
    height: 100%;
  }
`;

export const MainSC = styled.main`
  overflow-y: auto;
  margin-bottom: 40px;
  height: 100%;

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const ColumnSpinnerSC = styled(Box)`
  padding-top: 71px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingUserSpinnerSC = styled(Box)`
  background-color: ${({ theme }) => theme.palette.neutral50};
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//
//
// ---------------------------------------------------------------
// ---------------------- Form Elements -----------------------------
// ---------------------------------------------------------------

export const FormGroupsSC = styled(Box)`
  display: ${({ flexrow }) => (flexrow ? "flex" : "block")};
  justify-content: space-between;

  @media (max-width: 600px) {
    display: block;
  }

  // Form group - input & label & error
  .form-group {
    display: flex;
    flex-direction: column;
    width: ${({ flexrow }) => (flexrow ? "47%" : "100%")};

    @media (max-width: 600px) {
      width: 100%;
    }

    // Input and label
    & > div {
      display: flex;
      flex-direction: column-reverse;
    }

    label {
      font-family: ${({ theme }) => theme.fonts.h700};
      font-size: 12px;
      line-height: 24px;
      color: ${({ theme }) => theme.palette.primary800};
      margin-left: 24px;
      margin-bottom: 8px;
      width: fit-content;
    }

    input {
      border-radius: 24px;
      border: ${({ theme }) => `1px solid ${theme.palette.primary800}`};
      padding: 7px 23px;
      font-size: 12px;
      line-height: 24px;
      font-family: ${({ theme }) => theme.fonts.b400};
      font-weight: 400;
      color: ${({ theme }) => theme.palette.primary800};

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }

      &:focus {
        outline: none;
        background-color: ${({ theme }) => theme.palette.primary50};
        border-color: ${({ theme }) => theme.palette.primary500};

        & ~ label,
        & ~ div label {
          color: ${({ theme }) => theme.palette.primary400};
        }
      }

      &:disabled {
        border-color: #a2a1a4;
      }
    }

    &.error {
      label {
        color: ${({ theme }) => theme.palette.danger500} !important;
      }
      input {
        border-color: ${({ theme }) => theme.palette.danger500};
      }
    }
  }
`;

export const TextErrorSC = styled.p`
  min-height: 24px;
  font-size: 10px;
  line-height: 12px;
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.danger500};
  margin: 4px;
`;

export const CheckboxWrapperSC = styled(Box)`
  margin-bottom: 28px;
  & > div {
    display: flex;
    align-items: center;
  }

  label {
    font-size: 14px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.fonts.b400};
    font-weight: 400;
    color: ${({ theme }) => theme.palette.primary800};
  }
`;

export const CheckboxSC = styled(Checkbox)`
  &.MuiCheckbox-root {
    height: 24px;
    width: 24px;
    margin-right: 6px;
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary500} !important;
  }
`;
