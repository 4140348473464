import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import StyleProvider from "./config/styling";
import App from "./App";
import { FLAVOR } from "./config/constants";

function setDynamicMeta() {
  // Set title and description based on flavor
  const titleElement = document.getElementById("dynamic-title");
  const descriptionElement = document.getElementById("meta-description");

  if (FLAVOR === "som") {
    titleElement.innerText = "Som Vodovod";
    descriptionElement.setAttribute("content", "Som Vodovod");
  } else {
    titleElement.innerText = "Miholjački Vodovod";
    descriptionElement.setAttribute("content", "Miholjački Vodovod");
  }
}

setDynamicMeta();

ReactDOM.render(
  <React.StrictMode>
    <StyleProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StyleProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
