import { useState, useEffect } from "react";
import { view } from "@risingstack/react-easy-state";
import { useLocation } from "react-router-dom";

import {
  FooterSC,
  IconPrivacySC,
  TextPrivacySC,
  FooterLinksSC,
  NavbarLink,
} from "./styled";
import { useMediaQuery } from "@mui/material";
import { auth } from "../../stores/auth";
import { FLAVOR } from "../../config/constants";

const Footer = view(() => {
  const [firstRender, setFirstRender] = useState(true);
  const location = useLocation();
  const matches601Up = useMediaQuery("(min-width:601px)");

  useEffect(() => {
    setFirstRender(false);
  }, []);
  return (
    <>
      {(!matches601Up && auth.isLoggedIn) ||
      firstRender ||
      location.pathname.startsWith("/uspjesno-placanje") ||
      location.pathname.startsWith("/ponisteno-placanje") ? null : (
        <FooterSC>
          <TextPrivacySC>
            <span>&copy;</span>
            <p>2024. {FLAVOR === "som" ? "Som Vodovod d.o.o." : "Miholjački Vodovod d.o.o."} Sva prava pridržana.</p>
          </TextPrivacySC>
          <FooterLinksSC>
            <div>
              <IconPrivacySC />
            </div>
            <NavbarLink to="/politika-privatnosti">
              <p>Politika privatnosti</p>
            </NavbarLink>
            {/* <p>Cookie policy</p> */}
          </FooterLinksSC>
        </FooterSC>
      )}
    </>
  );
});

export default Footer;
