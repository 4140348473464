import { useState, useEffect } from "react";
import { view } from "@risingstack/react-easy-state";
import { NavLink, useLocation } from "react-router-dom";

import { auth } from "../../stores/auth";
import { notifications } from "../../stores/notifications";

import {
  AvatarSC,
  NavSC,
  NavWrapperSC,
  NavLinkListSC,
  LogoWrapperSC,
  IconButtonLinkSC,
  IconHomeSC,
  IconBillsSC,
  IconNotificationSC,
  Badge,
  IconInfoSC,
  IconLogoSC,
  IconButtonAvatarSC,
  SidebarUnauthenticatedSC,
  LogoFullSC,
} from "./styled";
import { FLAVOR } from "../../config/constants";
import LogoSOM from "../../assets/img/logo_som.svg";

const Sidebar = view(() => {
  const [activeSubRoute, setActiveSubroute] = useState("dashboard");
  const [firstRender, setFirstRender] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname.startsWith("/vodomjeri")
    ) {
      setActiveSubroute("dashboard");
    } else {
      setActiveSubroute("");
    }
  }, [location]);

  const getUserInitials = () => {
    let firstLetter = "";
    let secondLetter = "";
    if (auth.userDetails?.name?.split(" ").length > 1) {
      firstLetter = auth.userDetails?.name?.split(" ")[0][0];
      secondLetter = auth.userDetails?.name?.split(" ")[1][0];
    } else if (auth.userDetails?.name?.length) {
      firstLetter = auth.userDetails?.name?.[0];
    }
    let result = firstLetter?.toUpperCase() + secondLetter?.toUpperCase();
    if (!result) {
      return "K";
    }
    return result;
  };

  return (
    <>
      {firstRender ? (
        <div style={{ minWidth: "64px" }}></div>
      ) : (!auth.isLoggedIn || auth.isRegisterSuccess) &&
        !(location.pathname === "/info") ? (
        <SidebarUnauthenticatedSC>
          {FLAVOR === "som" ? (
            <img src={LogoSOM} alt="Som logo" style={{ width: "160px" }} />
          ) : (
            <LogoFullSC />
          )}
        </SidebarUnauthenticatedSC>
      ) : location.pathname === "/uspjesno-placanje" ||
        location.pathname === "/ponisteno-placanje" ? null : (
        auth.isLoggedIn && (
          <NavSC>
            <NavWrapperSC>
              <LogoWrapperSC>
                <NavLink to="/">
                  {FLAVOR === "som" ? (
                    <img src={LogoSOM} alt="Som logo" style={{ width: "40px" }} />
                  ) : (
                    <IconLogoSC />
                  )}
                </NavLink>
              </LogoWrapperSC>
              <NavLinkListSC>
                <IconButtonLinkSC
                  component={NavLink}
                  to="/"
                  activesubroute={activeSubRoute}
                >
                  <IconHomeSC />
                </IconButtonLinkSC>
                <IconButtonLinkSC component={NavLink} to="/racuni">
                  <IconBillsSC />
                </IconButtonLinkSC>
                <IconButtonLinkSC component={NavLink} to="/obavijesti">
                  <Badge
                    badgeContent={notifications.unreadCount}
                    color="primary50"
                  >
                    <IconNotificationSC />
                  </Badge>
                </IconButtonLinkSC>
                <IconButtonLinkSC component={NavLink} to="/info">
                  <IconInfoSC />
                </IconButtonLinkSC>
              </NavLinkListSC>
              <IconButtonAvatarSC>
                <AvatarSC
                  sx={{ width: 40, height: 40 }}
                  component={NavLink}
                  to="/profil"
                >
                  {getUserInitials()}
                </AvatarSC>
              </IconButtonAvatarSC>
            </NavWrapperSC>
          </NavSC>
        )
      )}
    </>
  );
});

export default Sidebar;
